<template>
  <div class="field is-grouped is-grouped-right">
    <div class="control">
      <button type="button" class="button is-link is-light" @click="showDraftsDialog = true" :disabled="disabled">Show Drafts</button>
      <Drafts-Dialog :isVisible="showDraftsDialog" @done="showDraftsDialog = false" />
    </div>
    <div class="control">
      <button type="submit" class="button is-success" @submit="$emit('submit', $event)" :disabled="disabled">Submit</button>
    </div>
    <div class="control">
      <button type="button" class="button is-link" @click="$emit('saveDraft', $event)" :disabled="disabled">Save Draft</button>
    </div>
    <div class="control">
      <button type="button" class="button is-link is-light" @click="$emit('cancel')" :disabled="disabled">Cancel</button>
    </div>
  </div>


</template>

<script>
import { defineComponent } from "vue";
import { DraftsDialog } from "@/components";

export default defineComponent({
  components: {
    DraftsDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["submit", "saveDraft", "cancel"],
  data() {
    return {
      showDraftsDialog: false
    }
  },
})
</script>