<template>
  <modal-dialog :active="showDraftModal" @done="done">
    <template v-slot:title>Save Draft</template>
    <template v-slot:body>
      <div class="instructions">
        <p class="subtitle is-6">
          Please enter a description to help you find this draft later.
        </p>
      </div>
      <div class="field">
        <div class="control is-expanded">
          <form :id="formId" novalidate @submit.prevent="done(true)">
            <form-input
              v-model="model.description"
              label="Description"
              required
            />
          </form>
        </div>
      </div>
    </template>
  </modal-dialog>
</template>

<script>
  import { formInput } from "hestia";
  import { modalDialog } from "aml";

  export default {
    components: {
      modalDialog,
      formInput
    },
    props: {
      modelValue: Object,
      registration: {
        type: Object,
        required: true,
      },
    },
    emits: ["done", "update:modelValue", "update:registrationTypeId"],
    inject: ["api"],
    data() {
      return {
        isLoading: false,
        showDraftModal: true,
        model: null,
        formId: "draftSaveForm"
      };
    },
    mounted() {
      this.model = this.modelValue;
      this.model.registration = JSON.stringify(this.registration);
      this.model.docs = this.registration.docs;
    },
    watch: {
      isLoading(newVal) {
        this.showDraftModal = !newVal;
        const that = this;
        this.$nextTick(() => {
          if (newVal)
            that.$hardLoading();
          else
            that.$doneLoading();
        })
      }
    },
    methods: {
      async done(proceed) {
        if (proceed) {
          const errors = this.$validate(this.formId);
          if(errors?.length) return;

          await this.save();
          this.$emit("done", true);
        }

        const that = this;
        this.showDraftModal = false;
        this.$closeModal(() => that.$emit("done"));
      },
      save() {
        this.isLoading = true;
        if (this.model.id) return this.update();
        else return this.insert();
      },
      insert() {
        const that = this;
        return this.api
          .addDraftRegistration(this.model)
          .then(this.handleApiResponse)
          .catch(this.$errorModal)
          .finally(() => that.isLoading = false);
      },
      update() {
        const that = this;
        return this.api
          .updateDraftRegistration(this.model)
          .then(this.handleApiResponse)
          .catch(this.$errorModal)
          .finally(() => that.isLoading = false);
      },
      reportSuccess() {
        this.$emit("update:modelValue", this.model);
        this.$toast({ message: "Draft saved succesfully", type: "success" });
      },
      updateRegistrationType(registrationJson) {
        if(!registrationJson) return;
        const registration = JSON.parse(registrationJson);
        if(!registration?.registrationTypeId) return;
        this.$emit("update:registrationTypeId", registration.registrationTypeId);
      },
      handleApiResponse(response) {
        this.model = response;
        this.updateRegistrationType(response?.registration)
        this.model.registration = null;
        this.reportSuccess();
      }
    },
  };
</script>

<style scoped>
  .instructions {
    margin-bottom: 1rem;
  }
</style>
