<template>
  <main class="section">
    <form @submit.prevent="submit" :id="formId" novalidate>
      <dirty-tracker v-if="isDirtyTrackingEnabled" :key="draft?.id" :tracked="model" />
      <div class="level">
        <div>
          <h1 class="title">{{ pageTitle }}</h1>
          <p class="subtitle">Request a new registration</p>
        </div>

        <div>
          <create-actions @save-draft="saveDraft" @cancel="cancelCreation()" :disabled="disableActions" />
        </div>
      </div>

      <article class="message is-danger" v-if="hasErrors">
        <div class="message-body">
          <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
      </article>

      <hr class="is-third is-centered" />
      
        <edit-form v-if="model" :key="draft?.id" v-model="model" @fileUploaded="onFileUploaded" @update:files="updateFiles" @fileDeleted="onFileDeleted($event)" @uploading="disableActions = $event"></edit-form>

        <create-actions @save-draft="saveDraft" @cancel="cancelCreation()" :disabled="disableActions" />

        <input type="hidden" :value="draft.id" name="draftRegistrationId" />
        <input type="hidden" :value="draft.rowVersion" name="draftRegistrationRowVersion" />
    </form>
  </main>
  <save-draft :registration="draftRegistration" v-if="showDraftDialog" v-model="draft" @done="draftDialogDone" @update:registrationTypeId="model.registrationTypeId = $event" />
</template>

<script>
import '@progress/kendo-ui/js/kendo.upload'
import saveDraft from './save-draft'
import createActions from './createActions'
import EditForm from "../edit-form";
import { defineComponent } from 'vue'
import { routeNames } from "@/router";
import { dirtyTracker } from 'hestia';

export default defineComponent({
  name: 'createRegistration',
  components: {
    saveDraft,
    createActions,
    EditForm,
    dirtyTracker
  },
  inject: ['api'],
  data() {
    return {
      showDraftDialog: false,
      draft: {},
      errors: null,
      disableActions: false,
      model: null,
      isDirtyTrackingEnabled: true,
      formId: "registrationForm",
      /** newFiles contains only valid files for submission/draft save */
      newFiles: [],
      draftRegistration: {}
    };
  },
  computed: {
    pageTitle() {
      return this.$route.meta.title;
    },
    requestor() {
      return this.$store.state['auth'].name;
    },
    hasErrors() {
      return this.errors && this.errors.length > 0;
    }
  },
  methods: {
    isValid() {
      const errors = this.$validate(this.formId);
      this.errors = errors;
      return errors.length === 0;
    },
    submit() {
      const that = this;
      if (!this.isValid()) return;
      this.$hardLoading();

      const model = this.model;
      model.docs = this.newFiles;
      model.draftRegistrationId = this.draft.id;
      model.draftRegistrationRowVersion = this.draft.rowVersion;
      return this.api.createRegistration(model)
        .then(response => {
          // Disable dirty tracking and navigate away
          this.isDirtyTrackingEnabled = false;
          that.$router.push({name: routeNames.registrationDetails, params: {registrationNumber: response}});
          // Do the page transition with loading ON. Will be dismissed on the detail page.
        })
        .catch((error => {
          that.$errorModal(error);
          this.$doneLoading();
        }));
    },
    saveDraft() {
      // create new draftRegistration object as we need to set newFiles instead of docs
      this.draftRegistration = this.model;
      this.draftRegistration.docs = [...this.newFiles];
      this.showDraftDialog = true;
    },
    draftDialogDone(proceed) {
      if (proceed) {
        this.model.docs = this.draft.docs;
        this.newFiles = [...this.model.docs];
        // Rerender dirty tracker to make a new snapshot of the model
        this.resetDirty();
      }

      const that = this;
      this.$closeModal(() => that.showDraftDialog = false);
    },
    onFileDeleted({ file, rowVersion }) {
      if(rowVersion) this.draft.rowVersion = rowVersion;
      this.newFiles = this.newFiles?.filter(f => file.referenceName !== f.referenceName) || [];
    },
    updateFiles(files) {
      this.model.docs = files;
    },
    onFileUploaded(file) {
      this.newFiles = [...this.newFiles, file];
    },
    async loadDraft() {
      const draftId = this.$route.query.draftId;
      if(!draftId) {
        this.draft = {};
        this.model = {};
        return;
      }

      this.$hardLoading();
      try {
        const draft = await this.api.getDraftRegistration(draftId);
        const draftModel = JSON.parse(draft.registration);
        await this.loadPrevRegistrationNumber(draftModel);
        this.draft.id = draft.id;
        this.draft.description = draft.description
        this.draft.rowVersion = draft.rowVersion;
        this.model = draftModel;
        this.newFiles = [...this.model.docs];
        if(this.model.startOfProduction) this.model.startOfProduction = new Date(this.model.startOfProduction);
      } catch (error) {
        this.$errorPage(error);
      } finally {
        this.$doneLoading();
      }
    },
    cancelCreation() {
      this.$router.push({ name: routeNames.home });
    },
    async loadPrevRegistrationNumber(draftModel) {
      if (draftModel.prevRegistrationId) {
        draftModel.prevRegistrationNumber = await this.api.getRegistrationNumber(draftModel.prevRegistrationId);
      }
    },
    resetDirty() {
      this.isDirtyTrackingEnabled = false;
      // Force Dirty Tracker to rerender
      this.$nextTick(() => this.isDirtyTrackingEnabled = true);
    }
  },
  mounted() {
    this.loadDraft();
  },
  watch: { 
    '$route'() {
        this.loadDraft();
    }
  }
});
</script>

<style scoped>
.columns.are-thirds > * {
  flex: none;
}

.columns.are-one-fifth > * {
  flex: none;
  width: 20%;
}
</style>